<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomButtons.EditButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && buttonData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && buttonData.isOk"
    class="form-custom-button-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-6">
        <label for="Name" class="form-label required"
          >{{
            $t(
              "CustomButtons.Name",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="buttonData.name"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-6">
        <label for="FormulaName" class="form-label required">{{
          $t(
            "CustomButtons.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="FormulaName"
          :disabled="true"
          :value="buttonData.formulaName"
          :autocomplete="this.$isAutoComplete"
          :spellcheck="this.$isTextSpellCheck"
          required
        />
      </div>
      <div class="col col-md-12 mt-2">
        <label for="Description" class="form-label">{{
          $t(
            "CustomButtons.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="buttonData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="1"
        ></textarea>
      </div>
      <div class="col col-md-6 mt-2">
        <label for="ButtonType" class="form-label required">{{
          $t(
            "CustomButtons.ButtonType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          @onChange="onChangeButtonType"
          :selectedData="selectedButtonType"
          :data="buttonTypeData"
          :allowEmpty="false"
        />
      </div>
      <div class="col col-md-6 mt-2">
        <label for="ButtonType" class="form-label required">{{
          $t(
            "CustomButtons.ForwardType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          @onChange="onChangeForwardType"
          :selectedData="selectedForwardType"
          :data="forwardTypeData"
          :allowEmpty="false"
          :resetSelect="resetSelect"
          :isDisabled="String.isNullOrWhiteSpace(buttonData.buttonTypeId)"
        />
      </div>
      <div class="col-md-6 mt-2" v-show="buttonData.buttonTypeId == 4">
        <label for="formTemplates" class="form-label required"
          >{{
            $t(
              "CustomObjects.FormTemplates",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeformTemplate"
          :isDetailIconShow="true"
          :routeLink="
            String.format(
              '#/CustomObject/EditFormTemplate/{0}&formTemplateId=',
              this.$route.params.customObjectId
            )
          "
          :isParameters="true"
          :allowEmpty="false"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryFormTemplates?coId={0}',
              this.$route.params.customObjectId
            )
          "
          :selectedData="selectedFormTemplate"
        />
      </div>
      <div v-if="buttonData.buttonTypeId == 1" class="col col-md-6 mt-2">
        <label for="BusinessRule" class="form-label required">{{
          $t(
            "CustomButtons.BusinessRule",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeBusinessRule"
          :allowEmpty="false"
          :isDetailIconShow="false"
          :isGatewayRequest="true"
          :isParameters="true"
          :selectedData="selectedBusinessRule"
          :resetSelect="resetSelect"
          :requestUrl="
            String.format(
              '/Brs-BusinessRulesOfCustomObjectWithFormula?customObjectFormulaName={0}',
              this.buttonData.customObjectFormulaName
            )
          "
        />
      </div>
      <div v-if="buttonData.buttonTypeId == 2" class="col col-md-6 mt-2">
        <label for="StaticLink" class="form-label required">{{
          $t(
            "CustomButtons.StaticLink",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          type="text"
          class="form-control"
          id="StaticLink"
          v-model="buttonData.staticLink"
          :spellcheck="this.$isTextSpellCheck"
          :autocomplete="this.$isAutoComplete"
          required
        />
      </div>
      <div v-show="buttonData.buttonTypeId == 3" class="col col-md-6 mt-2">
        <label for="Field" class="form-label">{{
          $t(
            "CustomButtons.Field",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithRemoteDataAndInternalSearch"
          @onChange="onChangeField"
          :isDetailIconShow="true"
          selectedPrimaryKey="publicId"
          :routeLink="
            String.format(
              '#/CustomObject/EditField/{0}&fieldId=',
              this.$route.params.customObjectId
            )
          "
          :allowEmpty="false"
          :selectedData="selectedField"
          :resetSelect="resetSelect"
          :isParameters="true"
          :isGatewayRequest="true"
          :requestUrl="
            String.format(
              '/Lcdp-SummaryAllTextFieldsWithFormula?parentFormulaName={0}',
              this.buttonData.customObjectFormulaName
            )
          "
        />
      </div>
      <div v-if="buttonData.forwardTypeId == 3" class="col col-md-6 mt-2">
        <label for="Slider" class="form-label"
          >{{
            $t(
              "CustomButtons.LightboxRate",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </label>
        <h6 class="badge badge-warning text-dark ms-1">
          {{ this.buttonData.lightBoxRatio }}
        </h6>
        <input
          type="range"
          class="form-range"
          min="1"
          max="100"
          v-model="buttonData.lightBoxRatio"
          @oninput="this.buttonData.lightBoxRatio = this.value"
        />
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="form-check">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsActive"
          v-model="buttonData.isActive"
        />
      </div>
      <div class="form-check">
        <label for="IsIconViewEnabled" class="form-label">{{
          $t(
            "CustomButtons.IsIconViewEnabled",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="IsIconViewEnabled"
          v-model="buttonData.isIconViewEnabled"
        />
      </div>
    </div>
    <div class="card mt-2" v-if="buttonData.isIconViewEnabled">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "CustomButtons.IsIconViewEnabled",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row align-items-end">
          <div class="col-6">
            <label for="Icon" class="form-label"
              >{{
                $t(
                  "CustomButtons.Icon",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <icon-picker
              v-model="buttonData.icon"
              :allIconsText="
                $t(
                  'Components.IconPicker.AllIcons',
                  {},
                  { locale: this.$store.state.activeLang }
                )
              "
            />
          </div>
          <div class="col-6">
            <label for="IconColor" class="form-label"
              >{{
                $t(
                  "CustomButtons.IconColor",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }} </label
            ><br />
            <ColorPicker
              :value="buttonData.iconColor"
              @valueChanged="colorPickerChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-check" v-if="buttonData.buttonTypeId == 3">
      <label for="SendDefaultValueWithButton" class="form-label">{{
        $t(
          "CustomButtons.SendDefaultValue",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</label>
      <input
        class="form-check-input"
        type="checkbox"
        id="SendDefaultValueWithButton"
        v-model="buttonData.sendDefaultValueWithButton"
      />
    </div>
    <div class="form-check">
      <label for="IsShowApprovalMessage" class="form-label">{{
        $t(
          "CustomButtons.IsShowApprovalMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</label>
      <input
        class="form-check-input"
        type="checkbox"
        id="IsShowApprovalMessage"
        v-model="buttonData.isShowApprovalMessage"
      />
    </div>
    <div v-if="buttonData.isShowApprovalMessage" class="col col-md-6 mb-1">
      <label for="ApprovalMessageContent" class="form-label required">{{
        $t(
          "CustomButtons.ApprovalMessageContent",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</label>
      <textarea
        name="ApprovalMessageContent"
        class="form-control"
        id="ApprovalMessageContent"
        v-model="buttonData.approvalMessageContent"
        :spellcheck="this.$isTextSpellCheck"
        rows="1"
        required
      ></textarea>
    </div>
    <div class="card mt-2" v-show="buttonData.sendDefaultValueWithButton">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-sliders"></i>
          {{
            $t(
              "CustomButtons.SendDefaultValue",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row align-items-end">
          <div class="col-6">
            <label for="SendDefaultValueObject" class="form-label"
              >{{
                $t(
                  "CustomButtons.SendDefaultValueObject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <FormSelect
              type="SelectWithRemoteDataAndInternalSearch"
              @onChange="onChangeForActionCustomObject"
              :allowEmpty="false"
              :selectedData="selectedSendDefaultValueObject"
              :isDetailIconShow="true"
              :routeLink="
                String.format(
                  '#/CustomObject/Detail/{0}',
                  this.$route.params.customObjectId
                )
              "
              :isGatewayRequest="true"
              :requestUrl="
                String.format(
                  '/Lcdp-SummaryCustomObjectsWithFormula',
                  this.$route.params.customObjectFormulaName
                )
              "
            />
          </div>
          <div class="col-6">
            <button
              type="button"
              class="btn btn-success getOtherFields float-end"
              v-if="otherFieldButtonShow"
              id="getOtherFields"
              @click="getOtherFieldsAction"
            >
              <i class="bi bi-plus"></i>
              {{
                $t(
                  "BusinessRuleActions.GetOtherFields",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
        <div
          class="col col-md-12 mt-3 brTable"
          :style="{
            display:
              buttonData.sendDefaultValueWithButton &&
              !String.isNullOrWhiteSpace(
                buttonData.sendDefaultValueCustomObjectPublicId
              )
                ? 'block'
                : 'none',
          }"
        >
          <Grid
            :settings="gridSettings"
            :columns="gridColumns"
            :rows="fieldList"
            @onFormulaEditorButtonClick="onFormulaEditorButtonClick"
          />
        </div>
      </div>
    </div>
    <div
      class="form-check"
      v-if="
        (buttonData.buttonTypeId == 1 && buttonData.forwardTypeId == 3) ||
        (buttonData.buttonTypeId == 3 && buttonData.forwardTypeId == 3)
      "
    >
      <label for="IsPageRefreshAfterProcessDone" class="form-label">{{
        $t(
          "CustomButtons.IsPageRefreshAfterProcessDone",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</label>
      <input
        class="form-check-input"
        type="checkbox"
        id="IsPageRefreshAfterProcessDone"
        v-model="buttonData.isPageRefreshAfterProcessDone"
      />
    </div>
    <div class="form-check">
      <label
        for="IsPermissionApply"
        class="form-label col col-md-6"
        data-bs-toggle="tooltip"
        data-bs-placement="left"
        :title="
          this.$t(
            'CustomButtons.ValidationTooltip',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
        >{{
          $t(
            "CustomButtons.IsValidationApply",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label
      >
      <input
        class="form-check-input"
        type="checkbox"
        id="IsPermissionApply"
        v-model="buttonData.isPermissionApply"
        @click="isCheckIsPermissionApply(buttonData.isPermissionApply)"
      />
    </div>
    <div v-if="this.isFormula" class="col col-md-6">
      <label for="Validation" class="form-label required">{{
        $t(
          "CustomButtons.Validation",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</label>
      <FormSelect
        type="SelectWithRemoteDataAndInternalSearch"
        :allowEmpty="false"
        :resetSelect="selectsReset"
        :isParameters="true"
        :isGatewayRequest="true"
        :selectedData="selectedValidation"
        openDirection="top"
        :requestUrl="
          String.format(
            '/Lcdp-SummaryButtonValidations?customObjectId={0}',
            this.$route.params.customObjectId
          )
        "
        @onChange="onChangeValidation"
      />
    </div>
    <div class="card mt-3" v-if="buttonData.isPermissionApply">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-ui-checks"></i>
          {{
            $t(
              "CustomButtons.ValidationAppearanceSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-check">
              <label
                for="IsApplyForHideShowOnViewFilter"
                class="form-label"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                :title="
                  this.$t(
                    'CustomButtons.ApplyHideShowMessage',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
                >{{
                  $t(
                    "CustomButtons.IsApplyForHideShowOnViewFilter",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="IsApplyForHideShowOnViewFilter"
                v-model="buttonData.isApplyForHideShowOnViewFilter"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check">
              <label
                for="IsApplyForHideShowOnPageLayout"
                class="form-label"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                :title="
                  this.$t(
                    'CustomButtons.ApplyHideShowMessage',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
                >{{
                  $t(
                    "CustomButtons.IsApplyForHideShowOnPageLayout",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="IsApplyForHideShowOnPageLayout"
                v-model="buttonData.isApplyForHideShowOnPageLayout"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <h3 class="card-title">
          <i class="bi bi-ui-checks"></i>
          {{
            $t(
              "CustomButtons.AppearanceSettings",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </h3>
        <div class="row mt-3">
          <div class="col-md-4">
            <div class="form-check">
              <label for="ShowAtDetailPage" class="form-label">{{
                $t(
                  "CustomButtons.ShowAtDetailPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="ShowAtDetailPage"
                v-model="buttonData.showAtDetailPage"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check">
              <label for="ShowAtNewPage" class="form-label">{{
                $t(
                  "CustomButtons.ShowAtNewPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="ShowAtNewPage"
                v-model="buttonData.showAtNewPage"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-check">
              <label for="ShowAtEditPage" class="form-label">{{
                $t(
                  "CustomButtons.ShowAtEditPage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                class="form-check-input"
                type="checkbox"
                id="ShowAtEditPage"
                v-model="buttonData.showAtEditPage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionButtons
      v-if="$isMobile()"
      classes="mt-2"
      @methodSave="onSubmit('btn-save')"
      :isNewRecord="false"
    />
  </form>
  <FormulaEditorModal
    :formulaField="formulaEditorFieldDto"
    @updateFormula="updateFormula"
  />
</template>
<script>
import $ from "jquery";
import IconPicker from "@/components/custom/icon-picker/IconPicker";
import FormulaEditorModal from "@/components/custom/formula-editor/FormulaEditorModal.vue";
export default {
  name: "CustomObjectEditButton",
  components: {
    IconPicker,
    FormulaEditorModal,
  },
  data() {
    return {
      resetSelect: false,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      buttonData: {},
      selectedButtonType: [],
      selectedForwardType: [],
      selectedField: [],
      selectedBusinessRule: [],
      selectedSendDefaultValueObject: [],
      selectedValidation: [],
      selectedFormTemplate: [],
      fieldValueFormulas: [],
      fieldList: [],
      orjFieldList: [],
      otherFieldButtonShow: false,
      formulaEditorFieldDto: null,
      isFormula: false,
      detailRequestEnd: false,
      buttonTypeData: [
        {
          key: "1",
          value: this.$t(
            "CustomButtons.BusinessRuleButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "CustomButtons.DirectForwardButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "CustomButtons.CustomForwardButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "CustomButtons.FormTemplateButton",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      forwardTypeData: [],
      allForwardTypesData: [
        {
          key: "1",
          value: this.$t(
            "CustomButtons.DirectForward",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "CustomButtons.NewTab",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "CustomButtons.LightBox",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      forwardTypeDataOnlyLightBox: [
        {
          key: "3",
          value: this.$t(
            "CustomButtons.LightBox",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      gridSettings: {
        action: "EditButtonFields",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "formula",
            cssClass: "btn-primary btn-formula-editor",
            iconClass: "sx-icon",
            routeButton: false,
            emitMethodName: "onFormulaEditorButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalFormulaEditor",
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Fields.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.IsRequired",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isRequired",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.Formula",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formula",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onFormulaEditorButtonClick(rowData) {
      this.formulaEditorFieldDto = rowData;
    },
    isCheckIsPermissionApply(selected) {
      if (selected == false) {
        this.isFormula = true;
        this.buttonData.isApplyForHideShowOnViewFilter = false;
        this.buttonData.isApplyForHideShowOnPageLayout = false;
      } else {
        this.isFormula = false;
        this.buttonData.validationName = "";
        this.buttonData.validationPublicId = "";
      }
    },
    onChangeValidation: function (selected) {
      this.buttonData.validationPublicId = selected.key;
      this.buttonData.validationName = selected.value;
    },
    onChangeformTemplate: function (selected) {
      this.buttonData.formTemplatePublicId = selected.key;
      this.buttonData.formTemplateName = selected.value;
    },
    updateFormula(formula, field) {
      this.formulaEditorFieldDto.formula = formula;

      var gridFieldIndex = this.fieldList.findIndex(
        (f) => f.publicId == field.publicId
      );

      var gridData = this.fieldList;
      gridData[gridFieldIndex].formula = formula;

      this.fieldList = [];
      this.fieldList = gridData;

      if (
        this.buttonData.fieldValueFormulas.some((l) => l.key == field.publicId)
      ) {
        if (String.isNullOrWhiteSpace(formula)) {
          this.buttonData.fieldValueFormulas =
            this.buttonData.fieldValueFormulas.filter(function (f) {
              return f.key !== field.publicId;
            });
        } else {
          this.buttonData.fieldValueFormulas =
            this.buttonData.fieldValueFormulas.filter(function (f) {
              return f.key !== field.publicId;
            });

          this.buttonData.fieldValueFormulas.push({
            value: formula,
            key: field.publicId,
          });
        }
      } else {
        if (!String.isNullOrWhiteSpace(formula)) {
          this.buttonData.fieldValueFormulas.push({
            value: formula,
            key: field.publicId,
          });
        }
      }
    },
    onSubmit(buttonName) {
      var form = $(".form-custom-button-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];

      if (this.buttonData.buttonTypeId != 3) {
        this.buttonData.fieldPublicId = null;
        this.buttonData.fieldName = null;
      } else if (this.buttonData.buttonTypeId != 2) {
        this.buttonData.staticLink = null;
      }
      if (this.buttonData.forwardTypeId != 3) {
        this.buttonData.LightBoxRatio = 0;
      }
      if (!this.buttonData.isIconViewEnabled) {
        this.buttonData.icon = null;
        this.buttonData.iconColor = null;
      }
      this.$prodGatewayAxios
        .post("/Lcdp-ButtonEdit", { ...this.buttonData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/Buttons/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    onChangeButtonType(selected) {
      this.buttonData.buttonTypeId = selected.key;
      this.resetSelect = !this.resetSelect;
      this.buttonData.staticLink = null;
      this.buttonData.fieldPublicId = null;
      this.buttonData.fieldFormulaName = null;

      this.buttonData.sendDefaultValueWithButton = false;
      this.buttonData.sendDefaultValueCustomObjectFormulaName = null;
      this.buttonData.sendDefaultValueCustomObjectPublicId = null;
      this.buttonData.sendDefaultValueCustomObjectName = null;
      this.buttonData.fieldValueFormulas = [];

      if (this.buttonData.buttonTypeId == 1) {
        this.forwardTypeData = this.forwardTypeDataOnlyLightBox;
      } else {
        this.forwardTypeData = this.allForwardTypesData;
      }
    },
    onChangeForwardType(selected) {
      this.buttonData.forwardTypeId = selected.key;
    },
    onChangeField(selected) {
      this.buttonData.fieldPublicId = selected.publicId;
      this.buttonData.fieldFormulaName = selected.key;
      this.buttonData.fieldName = selected.value;
    },
    onChangeBusinessRule(selected) {
      this.buttonData.BusinessRuleFormulaName = selected.key;
      this.buttonData.BusinessRuleName = selected.value;
    },
    colorPickerChange(value) {
      this.buttonData.iconColor = value;
    },
    getOtherFieldsAction() {
      this.fieldList = this.orjFieldList.sort(function (a, b) {
        return b.isRequired - a.isRequired;
      });
      this.otherFieldButtonShow = false;
    },
    onChangeForActionCustomObject: function (selected) {
      if (selected.key == null) {
        this.otherFieldButtonShow = false;
        $(".brTable").hide();
      } else {
        this.buttonData.sendDefaultValueCustomObjectFormulaName = selected.key;
        this.buttonData.sendDefaultValueCustomObjectPublicId =
          selected.publicId;
        this.buttonData.sendDefaultValueCustomObjectName = selected.value;
        this.buttonData.fieldValueFormulas = [];
        this.getFields(selected.key);
        this.otherFieldButtonShow = true;
        $(".brTable").show();
      }
    },
    getFields(coId, fieldValueFormulas = []) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-FieldList?customObjectPublicId={0}", coId))
        .then((response) => {
          var multipleSelect = this.multipleSelect,
            select = this.select,
            table = this.table,
            isMultiLanguage = this.$store.getters._isMultiLanguage;

          if (response.data.itemCount > 0) {
            response.data.items.forEach((v) => {
              v.MultipleSelectText = multipleSelect;
              v.SelectText = select;
              v.TableText = table;
              v.IsMultiLanguage = isMultiLanguage;
            });
          }

          this.fieldList = [];
          this.fieldList = response.data.items
            .filter(function (f) {
              return (
                f.isRequired ||
                (!f.isRequired &&
                  !String.isNullOrWhiteSpace(
                    fieldValueFormulas.find((d) => d.key == f.publicId)
                  ))
              );
            })
            .sort(function (a, b) {
              return b.isRequired - a.isRequired;
            });
          this.orjFieldList = response.data.items;

          if (!String.isNullOrWhiteSpace(fieldValueFormulas)) {
            this.fieldList.forEach((v) => {
              var selectedField = fieldValueFormulas.find(
                (f) => f.key == v.publicId
              );
              if (!String.isNullOrWhiteSpace(selectedField)) {
                v.formula = selectedField.value;
              }
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getButtonDetail() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-ButtonGet?id={0}&coId={1}",
            this.$route.params.buttonId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.buttonData = response.data;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            if (
              !String.isNullOrWhiteSpace(this.buttonData.fieldPublicId) &&
              !String.isNullOrWhiteSpace(this.buttonData.fieldName)
            ) {
              this.selectedField.push({
                publicId: this.buttonData.fieldPublicId,
                key: this.buttonData.fieldPublicId,
                value: this.buttonData.fieldName,
              });
            }
            if (
              !String.isNullOrWhiteSpace(this.buttonData.buttonTypeId) &&
              !String.isNullOrWhiteSpace(this.buttonData.buttonTypeName)
            ) {
              this.selectedButtonType.push({
                key: this.buttonData.buttonTypeId.toString(),
                value: this.buttonTypeData.filter(
                  (f) => f.key == response.data.buttonTypeId
                )[0].value,
              });
            }

            if (this.buttonData.buttonTypeId == 1) {
              this.forwardTypeData = this.forwardTypeDataOnlyLightBox;
            } else {
              this.forwardTypeData = this.allForwardTypesData;
            }

            if (
              !String.isNullOrWhiteSpace(this.buttonData.forwardTypeName) &&
              !String.isNullOrWhiteSpace(this.buttonData.forwardTypeId)
            ) {
              this.selectedForwardType.push({
                key: this.buttonData.forwardTypeId.toString(),
                value: this.forwardTypeData.filter(
                  (f) => f.key == response.data.forwardTypeId
                )[0].value,
              });
            }
            if (
              !String.isNullOrWhiteSpace(
                this.buttonData.sendDefaultValueCustomObjectPublicId
              )
            ) {
              this.selectedSendDefaultValueObject.push({
                key: this.buttonData.sendDefaultValueCustomObjectPublicId,
                value: this.buttonData.sendDefaultValueCustomObjectName,
              });
              this.getFields(
                this.buttonData.sendDefaultValueCustomObjectPublicId,
                this.buttonData.fieldValueFormulas
              );
              this.otherFieldButtonShow = true;
            }
            if (
              !String.isNullOrWhiteSpace(this.buttonData.businessRuleName) &&
              !String.isNullOrWhiteSpace(
                this.buttonData.businessRuleFormulaName
              )
            ) {
              this.selectedBusinessRule.push({
                key: this.buttonData.businessRuleFormulaName,
                value: this.buttonData.businessRuleName,
              });
            }
            if (this.buttonData.isPermissionApply == true) {
              // var val = false;
              // this.isCheckIsPermissionApply(val);
              this.isFormula = true;
              this.selectedValidation.push({
                key: this.buttonData.validationPublicId,
                value: this.buttonData.validationName,
              });
            }
            if (
              !String.isNullOrWhiteSpace(
                this.buttonData.formTemplatePublicId
              ) &&
              !String.isNullOrWhiteSpace(this.buttonData.formTemplateName)
            ) {
              this.selectedFormTemplate.push({
                key: this.buttonData.formTemplatePublicId,
                value: this.buttonData.formTemplateName,
              });
            }

            this.buttonData.customObjectFormulaName =
              this.$store.getters._customObjectDto.formulaName;
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getButtonDetail();
  },
};
</script>
